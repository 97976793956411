<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="currentChangeHandle(1)" @submit.native.prevent>
      <el-form-item>
        <el-input v-model="dataForm.key" placeholder="参数名" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="currentChangeHandle(1)" :loading="dataListLoading">查询</el-button>
        <el-button v-if="isAuth('dataflow:olfmajorfile:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
        <el-button v-if="isAuth('dataflow:olfmajorfile:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="mainId"
        header-align="center"
        align="center"
        label="main_id">
      </el-table-column>
      <el-table-column
        prop="fileName"
        header-align="center"
        align="center"
        label="文件名">
      </el-table-column>
      <el-table-column
        prop="fileType"
        header-align="center"
        align="center"
        label="01:-一般文件如word,02:录音文件">
      </el-table-column>
      <el-table-column
        prop="upDate"
        header-align="center"
        align="center"
        label="上传时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" v-if="isAuth('dataflow:olfmajorfile:update')" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button type="text" size="small" class="del-button" v-if="isAuth('dataflow:olfmajorfile:delete')" @click="deleteHandle(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
  import AddOrUpdate from './add-or-update'
  import { getList , deleteData } from '@/api/dataflow/olfmajorfile.js'
  export default {
    data () {
      return {
        dataForm: {
          key: ''
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalCount: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false
      }
    },
    components: {
      AddOrUpdate
    },
    activated () {
      //this.dataForm.key =  this.$route.query.key;

      let pageIndex = this.$route.query.pageIndex;
      if(pageIndex) {
        this.pageIndex = parseInt(pageIndex);
      }

      let pageSize = this.$route.query.pageSize;
      if(pageSize) {
        this.pageSize = parseInt(pageSize);
      }

      this.getDataList();
    },
    methods: {
       refresh(){
        // this.$router.push({name:'dataflow_olfmajorfile',query: {key:this.dataForm.key,pageIndex:this.pageIndex,pageSize:this.pageSize}});
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        const params_ = {
          'page': this.pageIndex,
          'limit': this.pageSize,
          'key': this.dataForm.key
        }
        getList(params_).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.data.list
            this.totalCount = data.data.totalCount
          } else {
            this.dataList = []
            this.totalCount = 0
            this.$message.error(data.msg)
          }
        }).catch((err) => {
            console.error(err)
        }).finally(()=>{
        	this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
        this.refresh()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
        this.refresh()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (row) {
        const ids = row ? [row.id] : this.dataListSelections.map(item => {
          return item.id
        })
        //const names = row ? [row.name] : this.dataListSelections.map(item => {
        //  return item.name
        //})
        
        this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dataListLoading = true
          deleteData(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000
              })
              this.getDataList()
            } else {
              this.$message.error(data.msg)
            }
          }).catch((err) => {
            console.error(err)
          }).finally(()=>{
          	this.dataListLoading = false
          })
        }).catch(_ => {})
      }
    }
  }
</script>
